$(document).ready(function() {
	var parent = $('body');
	var defaults = {
		selectAutoWidth: false
	};

	var inputs = parent.find('input:radio, input:checkbox, select').not('.js-ignore').not('.js-initDone').addClass('js-initDone');
	if(!inputs.length) { return; }

	inputs.each(function() {
		var obj = $(this);

		obj.uniform($.extend({},defaults,{ }));
	}).on('refresh',function() { $.uniform.update($(this)); });

	$('a[href^="#"]').on('click',function(e) {
		var target = $(this).attr('href');
		if(target.length <= 1) { return; }

		var obj = $('.js-'+target.substr(1));
		if(!obj.length) { return; }

		e.preventDefault();

		var offset = obj.offset().top;
		offset -= $('.header').height() - 2;

		console.log(offset);

		$('html,body').animate({
			scrollTop: offset
		},1000);
	});

	$('.js-toggle').on('click',function(e) {
		e.preventDefault();

		$('.header').toggleClass('header--active');
	});

	$('.js-sub-toggle').on('click',function(e) {
		e.preventDefault();

		$(this).toggleClass('header-menu__toggle--active').next().toggleClass('header-menu__sub--active');
	});

	$('.js-sidebar-toggle').on('click',function(e) {
		e.preventDefault();

		$(this).closest('.page__sidebar').toggleClass('page__sidebar--active');
	});

	$(window).on('scroll',function() {
		var scrollTop = $(window).scrollTop();

		if(scrollTop > 200) {
			$('.header').addClass('header--mini');
		}
		else {
			$('.header').removeClass('header--mini');
		}
	});

	$('.references__container').imagesLoaded().done(function() {
		$('.references__container').masonry({
			itemSelector: '.references__item',
			transitionDuration: 0
		});
	});
});
